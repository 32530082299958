import type { Breakpoints, Colors, Container, Fonts } from 'types/theme'

export const breakpoints: Breakpoints = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1440,
  xxxl: 1600,
}

export const colors: Colors = {
  primary: '#f39c12',
  secondary: '#2980b9',
  danger: '#e74c3c',
  success: '#2ecc71',
  light: '#FEF6E2',
  dark: '#242424',
  text: '#454545',
  white: '#FFFFFF',
  black: '#000000',
  gray: '#E8E7E7',
  lightgray: '#F8F8F8',
  red: '#df1629',
  yellow: '#FFC10E',
}

export const fonts: Fonts = {
  size: '16px',
}

export const container: Container = {
  widthNav: '1920px',

  widthXL: '1600px',
  width: '1600px',

  widthSlimXL: '1100px',
  widthSlim: '1100px',

  widthNarrowXL: '920px',
  widthNarrow: '920px',

  paddingMD: '48px',
  padding: '22px',
}

const theme = {
  breakpoints,
  colors,
  fonts,
  container,
}

export default theme
