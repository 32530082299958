import React, { createContext, useState } from 'react'
import { ImageDataLike } from 'gatsby-plugin-image'

type PopupContextProps = {
  popupData: {
    visible: boolean
    image: {
      src: ImageDataLike
      alt: string
    }
    link: string | null
    delay: number | null
    visibleOnMobile?: boolean
  } | null
  setPopupData: (value: any) => void
}

const PopupContext = createContext<PopupContextProps>({
  popupData: null,
  setPopupData: () => null,
})

export const PopupProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [popupData, setPopupData] = useState(null)

  return (
    <PopupContext.Provider
      value={{
        popupData,
        setPopupData,
      }}
    >
      {children}
    </PopupContext.Provider>
  )
}

export default PopupContext
